#side-bar{
    position: fixed;
    height: 100vh;
    z-index: 120;
    box-shadow: 2px 2px 5px grey;
    position: 'relative';
    white-space: 'nowrap';
    /* padding-top: 65px; */
}

#side-bar-toggle{
    display: none;
    transition: transform 0.4s ease-out;
    width: 50px;
    height: 50px;
    background-color:transparent;
    margin-top: 5px;
    margin-left: auto;
    margin-right: auto;
    border-color: transparent;
    outline: none;
    border-radius: 15px;
}

#side-bar-toggle:hover {
    background-color:rgba(128, 128, 128, 0.164);
    cursor:pointer;
  }

@media (max-width: 1700px)  {
    #side-bar-toggle{
        display: block;
        border: none;

    }
}

.open{
    transform: rotate(90deg);
}

