.home-page{
  text-align: center;
  vertical-align: auto;
  height: 100vh;
 
}

#homepage-text{
  margin-top: 15%;
}

#home-signup{
  background-color: transparent;
  color: var(--primary-color);
  font-weight: 2000;
  font-size: 1em;
  border: 5px solid var(--primary-color);
  border-radius: 20px;
  box-shadow: none;
}

.separator {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  width: 100%;
  visibility: visible;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: xx-large;
  font-weight: 700;
}

.divider{
  width: 100%;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
}
.separator::before, .separator::after {
  content: '';
  flex: 1;
  border-bottom: 3px solid #000;
}
.separator::before {
  margin-right: .35em;
}
.separator::after {
  margin-left: .35em;
}

#login-page{
  height: 100vh;
}
#login-page-content{
  margin-top: 7%;
}
.paper {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.avatar {
  margin: 10px;
  background-color: red;
}
.form {
  width: 100%;
  margin-top: 10px
}
.submit {
  margin: 190px;
}

#signup-link{
  margin-top: 20px;
  font-size: 1.5em;
}

.google-login-container {
  text-align: center;
  display:table-cell;
  vertical-align:middle;
}