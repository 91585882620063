#professional-editing-page {
  display: flex;
  flex-direction: column;
  color: var(--font-label-color);
  align-items: flex-start;
}

.row-container.professional {
  justify-content: space-between;
  width: 100%;
}

.row-container.professional > * {
  width: 30%;
  margin: 1%;
}

.professional-comment-box {
  width: 80%;
}