/*  */
/* Laptop-L */
/*  */
@media (max-width: 1440px)  {
  .home-page {
    margin-left: 150px !important;
  }

  #home-page-info {
    margin: 15px;
    padding-left: 34px;
    padding-right: 34px;
    border-radius: 5px;
  }

  .home-page-options {
    width: 200px;
    height: 50px;
    margin: 20px;
    padding: 10px;
    border-radius: 5px;
  }

  .home-page-options > h2, .home-page-options > .row-container > h2 {
    font-size: 12px;
  }

  .home-page-options > span, .home-page-options > .row-container > span {
    margin-top: -35px;
  }

  .home-page > h1 {
    font-size: 1.5em;
  }

  .home-page-options.column {
    height: 290px;
    font-size: 11px;
  }

  .home-page-info-arrow {
    margin-top: 20px;
    font-size: 1.5em;
  }

  .narration-label {
    font-size: 20px 
  }

  .narrations-table {
    width: 200px;
    height: 100%;
    margin: 10px;
    padding-left: 0px;
    padding-right: 55px;
  }

  .feeds-table > .pointer > td {
    /* all: unset; */
    -webkit-appearance: none;
    font-size: 20px;
  }
}

/*  */
/* Tablet */
/*  */
@media (max-width: 768px)  {
  .home-page {
    width: 75vw;
    margin-left: 0px !important;
  }
  #home-page-info {
    width: 100%;
  }

  .home-page-options {
    width: 120px;
    height: 25px;
    padding: 10px;
    margin: 5px;
  }

  .home-page-options.column {
    height: 220px;
  }

  .home-page-options > p, .home-page-options > h3 {
    font-size: 0.8em;
  }
  .home-page-options > span, .home-page-options > .row-container > span {
    margin-top: -25px;
    font-size: 1em;
  }

  .home-page-options > h2, .home-page-options > .row-container > h2 {
    font-size: 10px;
  }

  .narrations-table {
    padding-left: 55px;
  }

  .narrations-table > * > .pointer {
    -webkit-appearance: none;
    font-size: 20px;
    width: 78vw;
    margin-left: -5vw;
  }

  .home-page-icon {
    padding: 4px;
    margin-right: 10px;
    border-radius: 100%;
    font-size: 15px !important;
  }
}

/*  */
/* Mobile-L */
/*  */
@media (max-width: 425px)  {
  .home-page {
    width: 60vw;
    font-size: 0.7em;
  }
  #home-page-info {
    font-size: 0.65em;
    width: 90%;
    margin-left: 0;
  }

  .home-page-options {
    width: 70px;
    height: 25px;
    padding: 5px;
    margin: 5px;
  }

  .home-page-options.column {
    height: 220px;
  }

  .home-page-options > p, .home-page-options > h3 {
    font-size: 0.6em;
  }
  .home-page-options > span, .home-page-options > .row-container > span {
    margin-top: -25px;
    font-size: 1em;
  }

  .home-page-options > h2, .home-page-options > .row-container > h2 {
    font-size: 6px;
  }

  .home-page-icon {
    padding: 4px;
    margin-right: 10px;
    border-radius: 100%;
    font-size: 15px !important;
  }

  .home-table  {
    margin-left: -10px;
  }

  .narration-label {
    font-size: 0.5em;
  }
  
  .narrations-table > * > .pointer {
    width: 61vw;
    margin-left: -7vw;
  }
}

/*  */
/* Mobile-M */
/*  */
@media (max-width: 375px)  {
  #home-page-info {
    font-size: 0.65em;
  }

  .home-page-options {
    width: 60px;
  }

  .home-page-options > span, .home-page-options > .row-container > span {
    margin-top: -25px;
    font-size: 1.3em;
  }

  .home-page-options > p, .home-page-options > h3 {
    font-size: 0.55em;
  }

  .home-page-options > h2, .home-page-options > .row-container > h2 {
    font-size: 5px;
  }

  .home-page-icon {
    font-size: 15px !important;
  }

  .home-table  {
    margin-left: -50px;
  }

  .narration-label {
    font-size: 0.4em;
  }
  .narrations-table > * > .pointer {
    margin-left: 0vw;
  }
}