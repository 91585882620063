.FormRow {
  border-bottom: 1px solid grey;
  text-align: center;
  width: 80%;
}
.card-holder{
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElement {
  width: 100%;
  padding: 11px 15px 11px 0;
  border: 1px solid #eee;
}

.error-text{
  color: red
}