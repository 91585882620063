.tier-card{
    width: 252px;
    height: 275px;
    font-size: 60px;
    color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding-bottom: 10px;
}

.free-tier{
    background-color: var(--free-tier-color);
}
.pro-tier{
    background-color: var(--pro-tier-color);
}
.corporate-tier{
    background-color: var(--corporate-tier-color);
}
.enterprise-tier{
    background-color: var(--enterprise-tier-color);
}

.tier-card-title{
    margin-top: 15px;
    font-size: 27px;
    font-weight: bold;
}

.tier-card-price{
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    font-size: 75px;
}

.monthly-text{
    font-family: 'Raleway', sans-serif;
    margin-top: 35px;
    font-size: 17px;
    font-weight: bold;
}
.price-container{
    display: flex;
    align-items: center;
}

.tier-info{
    font-family: 'Raleway', sans-serif;
    font-size: 12px;
    font-weight: normal;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    min-height: 20px;
}

.tier-card-big-text{
    font-family: var(--pricing-font-family);
    font-weight: bold;
    font-size: 30px;
}
.contact-email-message{
    margin-top: 20px;
}

.contact-sales-text{
    margin-top: 40px;
}