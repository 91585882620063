.table-double{
    background-color: red;
}

.row-float-bottom{
  margin-top: auto !important;
  margin-bottom: 0 !important;
}

.pointer {
    -webkit-text-fill-color: #7B7B7B;
}

.pointer > .i > {
    -webkit-text-fill-color: white;
}