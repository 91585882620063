.settings-page {
  color: var(--font-label-color);
  text-align: left;
  padding-bottom: 75px;
}
.underline {
  border-top: 3px solid var(--font-label-color);
  width: 100%;
  margin-top: 22px;
}

.settings-icon {
  background-color: var(--secondary-label-color) !important;
  border-radius: 100%;
  font-size: 50px !important;
  padding: 5px;
  color: white !important;
}
.settings-section {
  display: flex;
  margin-top: 20px;
}
.settings-section > main {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}
.settings-section > main > .row-container {
  align-items: center;
  justify-content: flex-start;
}
.settings-section > main > .row-container > * {
  align-items: center;
}
.settings-button {
  color: var(--font-label-color);
  margin: 20px !important;
  cursor: pointer;
}
#no-podcast {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.subscription-link{
  margin: 30px;
  color: var(--primary-color);
  -webkit-text-fill-color: var(--primary-color);
}

.horizontal-row-settings-page{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.settings-page-medium-title{
  margin-right: 20px;
  font-size: 20px;
  font-weight: normal;
}

.clickable-text-medium {
  color: var(--primary-color);
  -webkit-text-fill-color: var(--primary-color);
}

.settings-page-info-text{
  font-family: var(--font-family);
  font-weight: normal;
  font-size: 20px;
}

#plus-button{
  color: var(--secondary-label-color) !important;
}

.podcast-info-text{
  position: relative;
  right: 18px;
}

.align-to-right{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
}

.vertical-flex-box-settings-page{
  display: inline-flex;
  flex-direction: column;
}

.settings-page-icon-title{
  display: flex;
  position: relative;
  right: 10px;
  align-items: center;
  flex-direction: row;
}
.setting-section-label{
  margin-left: 10px;
}

.icon-positioning{
  position: relative;
  top: 5px;
}

.clickable-embedded-text{
  color: red;
  font-weight: normal;
}
.clickable-embedded-text:hover{
  cursor: pointer;
  font-weight: bolder;
}