.popup-modal {
  min-height: 20vh;
  width: 10vw;
}

@media (max-width: 1440px)  {
  .popup-modal {
    min-height: 30vh;
    width: 15vw;
  }
}
#iframe-dialog-container{
  all: unset;
  -webkit-appearance: none;
  display: flex;
  flex-direction: column;;
}
.popup-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  color: gray;
  text-align: center;
}
.warning {
  color: var(--secondary-label-color);
  -webkit-text-fill-color: var(--secondary-label-color);

}
.warning-icon {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 17vh;
}
.warning-icon > * {
  position: absolute;
}

.warning-icon > .exclamation {
  margin-right: 25px;
}

#popup-content {
  display: flex;
  justify-content: flex-start;
}
#popup-content > button {
  border: none;
  background-color: transparent;
}
#popup-content > span {
  color:var(--primary-color);
}
#popup-content > .unSelected {
  color: var(--font-label-color)
}
#alert-dialog-title{
  color: var(--primary-color);
  font-family: 'Montserrat', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-size: 44px;
  font-weight: 700;
}
#alert-dialog-description{
  font-size: 22px;
}