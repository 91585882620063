.tiers-container {
    margin-top: 50px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: row;
}

.flex-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 275px;
}
@media (max-width: 1600px)  {
    .pricing-modal{
        min-width: 85vw;
    }

}
@media (min-width: 1700px)  {
    .flex-column {
        margin-left: 25px;
        margin-right: 25px;
    }
}

.tier-list-title {
    font-family: var(--pricing-font-family);
    font-size: 20px;
    font-weight: bold;
}

.free-tier-color {
    color: var(--free-tier-color)
}

.pro-tier-color {
    color: var(--pro-tier-color);
}

.corporate-tier-color {
    color: var(--corporate-tier-color);
}
.enterprise-tier-color{
    color: var(--enterprise-tier-color);
}
.tier-list ul,
.tier-list li {
    text-align: left;
    margin: 0;
    padding: 0;
    list-style-type: disc;
}

.tier-list {
    color: var(--font-label-color);
    font-family: var(--pricing-font-family);
    font-size: 15px;
    max-width: 270px;
    padding-right: 10px;
}

.corporate-tier-color ul li::marker {
    color: var(--corporate-tier-color);
    font-size: 1.25em;
}

.free-tier-color ul li::marker {
    color: var(--free-tier-color);
    font-size: 1.25em;
}

.pro-tier-color ul li::marker {
    color: var(--pro-tier-color);
    font-size: 1.25em;
}

.enterprise-tier-color ul li::marker {
    color: var(--enterprise-tier-color);
    font-size: 1.25em;
}

.pricing-modal{
    font-family: var(--pricing-font-family);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70vw;
}
.interval-selector{
    margin-top: 20px;
}
.tier-list-sub-title{
    font-family: var(--pricing-font-family);
    font-size: 17px;
    font-weight: normal;
}

.pricing-close-button{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 10px;
    position: absolute;
    right: 0;
}
.pricing-close-button:hover{
    cursor: pointer;
}

.text-align-left-pricing-info{
    margin-top: 10px;
    text-align: left;
}

