.audio-btn{
    margin-left: 20px;
}

.audio-btn :hover{
    cursor: pointer;
}

.small-play-icon {
    cursor: pointer;
}