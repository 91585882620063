.checkout-container {
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
  }

.form-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  height: 100%;
}

.item-section {
  width: 50%;
  height: 100%;
}

.checkout-content{
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.coupon-section{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 350px;
  text-align: center;
}

.coupon-message{
  position: absolute;
  height: 50px;
  border-radius: 20px;

}

.message-spacer{
  visibility: hidden;
  height: 90px;
  background-color: purple;
}

.percent-off{
  padding: 15px;
  background-color: rgb(122, 223, 34);
  z-index: 2;
}

.discount{
  display: flex;
  flex-direction: column;
  z-index: 999;
}

.coupon-button{
  margin-top: 15px;
  background-color: royalblue;
  padding: 10px;
  font-size: 17px;
  color: white;
  border-radius: 10px;
}

.coupon-button:hover{
  cursor: pointer;
  background-color: rgb(96, 128, 224);
}