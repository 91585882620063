#analytics-page {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.time-button {
	margin-bottom: 20px;
}
.charts-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	width: 100%;
	height: 500px;
	margin-bottom: 30px;
}
.charts-container > * {
	width: 100%;
	height: 100%;
}
.counts {
	height: 300px;
}
.counts > div > div {
	height: 200px;
	margin-bottom: -15px;
}
.dataVisualChart > * {
	margin-left: auto;
	margin-right: auto;
	height: auto;
}
.countChartContainer {
	height: 100;
	margin-bottom: 30px;
}
.countPercentChange {
	display: flex;
	justify-content: center;
	align-items: center;
}
.percentArrowBox {
	width: 25px;
	height: 25px;
	margin-left: 10px;
	background-color: rgb(40, 40, 40);
	border-radius: 5px;
}
.celebrationIcon {
	width: 30px !important;
	height: 30px !important;
	margin-top: 10px !important;
}
.celebrationIcon > * {
	width: 100%;
	height: 100%;
	margin-top: 10px !important;
}
.percentArrowBox > * {
	width: 25px;
	height: 25px;
	box-sizing: unset;
}

.averageChart > h1 {
	font-size: 4.5em;
	font-weight: 400;
}
.celebration-tooltip {
	display: "flex" !important;
	justify-content: "center" !important;
	align-items: "center" !important;
	height: "15%" !important;
}

@media only screen and (max-width: 1280px) {
	.charts-container.top {
		flex-direction: column;
		height: 750px;
	}
}

@media only screen and (max-width: 1024px) {
	.charts-container {
		flex-direction: column;
		width: 120%;
	}
	.charts-container.middle {
		height: 1000px;
	}
	.dataVisualChart > * {
		margin-left: auto;
		margin-right: 0;
	}
	.counts {
		flex-direction: row;
		height: 200px;
	}
}
