
@media (max-width: 1440px)  {
    .file-info-page {
     
        width: 68vw;
        /* -webkit-text-fill-color: #7B7B7B; */
        
    }
}

#header > .row-container {
    /* all: unset;
  -webkit-appearance: none; */
font-size: 20px;

}

.iframe-container{
  all: unset;
  -webkit-appearance: none;
  margin: 30px;
}

#no-audio-text{
    font-size: 30px;
    margin-top: 20px;
}

@media (max-width: 1440px)  {
    #no-audio-text{
        font-size: 25px;
        margin-top: 25px;
    }
}

.file-info-distribution-options{
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.file-info-page > .file-info-distribution-options > .row-container {
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    justify-content: flex-start;
}

.file-info-option {
    color: var(--font-label-color);
    padding-left: 15px;
    padding-right: 15px;
    border-bottom: 5px solid var(--border-color); 
    cursor: pointer;
    
}

.file-info-option.filler {
  width: 35%;
}

.file-info-option.selected {
    color: var(--primary-color);
    border-color: var(--primary-color);
    -webkit-text-fill-color: #64E43C;

}

.file-options-container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    color: var(--font-label-color);
}


.iframe-code-container {
    border: 2px solid #c2c2c1;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 1.5em;
    width: 50%;
    white-space: nowrap;
    overflow-x: hidden;
    align-items: center;
    display: flex;
}
@media (max-width: 1440px)  {
    .iframe-code-container {
        border: 2px solid #c2c2c1;
        box-sizing: border-box;
        border-radius: 4px;
        font-size: 1em;
        width: 50%;
        white-space: nowrap;
        overflow-x: hidden;
        align-items: center;
        display: flex;
    }
    .file-info-option.filler {
      width: 25%;
    }
}

.download-button {
    border: 3px solid #64E43C;
    border-radius: 5px;
    padding: 10px;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: auto;
    margin-left: auto;
    font-size: 1.5em;
    font-weight: bolder;
    color: var(--font-label-color);
    text-decoration: none;
    cursor: pointer;
}       
@media (max-width: 1440px)  {
    .download-button {
        border: 3px solid #64E43C;
        border-radius: 5px;
        padding: 10px;
        width: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: auto;
        margin-left: auto;
        font-size: 1em;
        font-weight: bolder;
        color: var(--font-label-color);
        text-decoration: none;
        cursor: pointer;
    }
  .publish-container {
    margin-left: -30px !important;
  }
}

.iframe-instructions {
  font-size: 1.35em;
}

@media (max-width: 1440px)  {
    .iframe-instructions {
        font-size: 1.1em;
      }
}

.row-container > .h2 {
    -webkit-text-fill-color: var(--primary-color);

}

.grid-container {
    margin-bottom: 50px;

}

.upload-icon {
  border-radius: 100%;
  width: 50px;
  height: 50px;
  font-size: 2.4em;
  color: var(--secondary-label-color);
  margin-top: 15px;
}