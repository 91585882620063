.root {
  background-color:white;
  -webkit-background-color: white;
}

#file-info-button-container{
  all: unset;
  -webkit-appearance: none;
  display: flex;
  flex-direction: column;
  width: 100px;
}

.distribute-button {
  -webkit-appearance: none;
  border: none;
  background-color: transparent;
  font-size: 25px;
  cursor: pointer;
}

.delete-button {
  -webkit-appearance: none;
  border: none;
  background-color: transparent;
  color: rgb(255, 79, 79);
  font-size: 1.5em;
  cursor: pointer;
}

.deletePopup {
  -webkit-appearance: none;
  height: 50vh;
  width: 30vw;
}

.deletePopup > * {
  -webkit-appearance: none;
  color: rgb(255, 79, 79);
  text-align: center;
}


.delete-button-popup {
  -webkit-appearance: none;
  border: 3px solid #FF4141;
  border-radius: 10px;
  background-color: transparent;
  color: var(--font-label-color);
  font-size: 1.5em;
  cursor: pointer;
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
  z-index: 99;
  position: relative;
}

.podcastPopup {
  -webkit-appearance: none;
  width: 30vw;
}

.podcastPopup > * {
  -webkit-appearance: none;
  color: var(--primary-color);
  text-align: center;
}

button > .request-popup {
  -webkit-appearance: none;
  position: absolute;
  color: gray;
  padding: 5px;
  background-color: #E5E5E5;
}

.invisible {
  -webkit-appearance: none;
  position: absolute;
  opacity: 0;
}

.custom-star {
  -webkit-appearance: none;
  color: #ffde59;
  filter: drop-shadow(2px 2px 0px #ff8828);
  font-size: 2em;
}

.narration-play-icon {
  -webkit-appearance: none;
  display: flex;
  flex-direction: row;
  width: fit-content;
  text-overflow: ellipsis;
}

.narration-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 270px;
}

.narration-tags {
  -webkit-appearance: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.narration-tags > *{
  all: unset;
  -webkit-appearance: none;
  font-size: 0.5em;
  background-color: var(--font-label-color);
  border-radius: 100px;
  padding: 5px;
  margin: 2px;
  width: 60%;
  color: white;
  font-weight: bolder;
}

.podcast-episode {
  -webkit-appearance: none;
  background-color: #4875EA;
}

.custom-narration {
  -webkit-appearance: none;
  background-color: #FFCF0C;
}

.in-progress-custom {
  -webkit-appearance: none;
  background-color: #FF545E;
}

.search-bar{
  -webkit-appearance: none;
  width: 200px;
  height: fit-content;
  border-radius: 100px;
  border: 2px solid var(--font-label-color);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: auto;
}

.search-bar > svg{
  -webkit-appearance: none;
  padding: 5px;
  font-size: 2em !important;
  color: var(--font-label-color) !important;
}

.search-bar > input{
  -webkit-appearance: none;
  border: none;
  background-color: transparent;
  width: 100%;
  font-size: 1em;
  color: var(--font-label-color);
}

.search-bar > input:focus{
  -webkit-appearance: none;
  border: none;
  outline: none;
}

.play-icon-narration-list{
  position: relative;
  bottom: 5px;
  margin-right: 10px;
}
.narrations-table > thead {
  -webkit-text-fill-color: #7B7B7B;
}

.narrations-table > thead > tbody > .pointer > td{
  -webkit-text-fill-color: #7B7B7B;
}