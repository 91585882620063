.history-bar {
  display: flex;
  justify-content: flex-start;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 15px;
}

@media (max-width: 1440px)  {
  .history-bar {
    display: flex;
    justify-content: flex-start;
    font-size: 2em;
  }
}
.history-item {
  color: gray;
  margin-right: 10px;
  cursor: pointer;
  text-overflow: ellipsis;

}

.history-item:hover, .history-item:active {
  font-size: 85%;
}
.current-item {
  width: 25vw;
  white-space:nowrap;
  overflow: hidden;
  text-align: left;
  background-image: linear-gradient(to right, var(--primary-color) 93%, white 100%);
  color: transparent;
  background-clip: text;
  text-overflow: ellipsis;
  /* -webkit-text-fill-color: #64E43C; */

}

@media (max-width: 1440px)  {
  .current-item {
    width: 25vw;
    white-space:nowrap;
    overflow: hidden;
    text-align: left;
    -webkit-overflow-background-image: linear-gradient(to right, var(--primary-color) 90%, white 100%);
    color: transparent;
    background-clip: text;
    text-overflow: ellipsis;

    /* -webkit-text-fill-color: #64E43C; */

  }
  
}

@media (max-width: 768px)  {
  .history-bar {
    font-size: 1.5em;
  }
  .current-item {
    width: 40vw;
  }
}

@media (max-width: 425px)  {
  .history-bar {
    font-size: 1em;
  }
}

.current-item-podcast {
  white-space:nowrap;
  overflow: hidden;
  text-align: left;
  color: var(--primary-color);
  
}