@media (max-width: 425px){
  .settings-section > main {
    margin-left: -40px;
  }
  .settings-section > main > h1 {
    margin-left: 40px;
  }
  .settings-page-info-text {
    font-size: 1em;
  }
}