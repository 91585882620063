/* #new-podcast-page {

} */

.podcast-form {
  width: 100%;
  text-align: left;
  color: var(--font-label-color);
}

.podcast-title {
  color: var(--secondary-label-color);
}
.image-dropzone {
  display: flex;
  flex-direction: column;
}
.image-dropzone > img {
  border: 2px solid var(--secondary-label-color);
  max-width: 20vw;
  max-height: 20vw;
}
.podcast-description {
  width: 75%;
}