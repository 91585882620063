#narrations-list{
  max-height: 400px;
}

#side-bar-content{
  margin-top: 10px;
}

#side-bar-accordion{
  
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  border-color: white;
  border-width: 2px;
  outline-color: transparent;

  /* transition: 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 0ms; */
}

#analytics-button{
  padding: 0;
  box-shadow: none;

}

#expand-analytics-button{
  margin-right: 15px;
}

/* #add-icon{
  color:#87287c;
}
#file-icon{
  color: #3F5270;
}
#analytics-icon{
  color: #3F7045;
}

#invite-icon{
  color: #b44444;
} */

.sidebar-icon, .side-bar-button-text {
  color: var(--font-label-color) !important;
  font-size: 3em !important;
  margin-bottom: 10px;
  margin-top: 10px;
  font-weight: bolder;
}

.icon {
  color: var(--primary-color) !important;
  -webkit-text-fill-color: var(--primary-color) !important;
}
#sidebar-title {
  color: var(--font-label-color);
  font-family: Montserrat;
}
#side-bar-logo {
  all: unset;
  -webkit-appearance: none;
  -webkit-height: 50px;
  -moz-height: 50px;
  -o-height:50px;
  -ms-height:50px;
  height: 50px;
  padding: 20px;
  padding-left: 5px;
  transform: rotate(25deg);
}
.side-bar-help-button {
  /* margin-top: 40vh !important; */
  position: fixed !important;
  bottom: 0;
}
.side-bar-help-button > a {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
}
@media (max-width: 1440px)  {
  #side-bar-logo {
    padding-left: 0px;
  }
}

@media (max-width: 425px)  {
  #side-bar-logo {
    height: 45px;
    padding-left: -5px;
  }
  .sidebar-icon, .side-bar-button-text {
    color: var(--font-label-color) !important;
    font-size: 2.5em !important;
    margin-bottom: 10px;
    margin-top: 10px;
    font-weight: bolder;
  }
  .side-bar-help-button {
    /* margin-top: 40vh !important; */
    position: absolute !important;
    margin-left: -10px;
  }
}