.signup-btn{
    color: var(--primary-color);;
    background-color: transparent;
    border-color:  var(--primary-color);;
    border-radius: 60px;
    font-size: 20px;
    padding-left: 25px;
    padding-right: 25px;
    margin: 25px;
    border-style: solid;
  }

  .verify-btn{
    color: white;
    background-color:  var(--primary-color);;
    border-color:  var(--primary-color);;
    border-width: 10px;
    border-radius: 60px;
    font-size: 50px;
    padding-left: 25px;
    padding-right: 25px;
    margin: 25px;
    border-style: solid;
  }
  
  .verify-btn:hover{
    cursor: pointer;
  
    background: white;
    color:var(--primary-color);;
  
  }