
.error-text{
    color: red
  }

.successful-payment{
  display: flex;
  justify-content: center;
  align-items: center;
}
.success-message{
  padding: 200px;
  font-size: 40px;
}
.payment-form-message{
  font-size: 30px;
  padding: 10px;
}