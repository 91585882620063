.product-button {
  font-family: var(--pricing-font-family);
  font-weight: bold;
  font-size: 15px;
  background-color: rgba(0, 0, 0, 0.212);
  border-radius: 10px;
  padding: 5px 15px 5px 15px;
  margin-bottom: 20px;
}
.product-button:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.466);
}

.product-button-disabled {
  font-family: var(--pricing-font-family);
  font-weight: bold;
  font-size: 19px;
  border-radius: 10px;
  padding: 5px 15px 5px 15px;
  border-style: solid;
}

a.stripe-link {
  text-decoration: none;
  font-family: var(--pricing-font-family);
  color: white;
}
