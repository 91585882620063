.new-rss-options > .row-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	width: fit-content;
}
.new-rss-options > .row-container > h2 {
	text-align: right;
	color: var(--secondary-label-color);
	margin-right: 20px;
	font-weight: bolder;
	width: 200px;
}
.new-rss-options > .row-container > * {
	width: auto;
}

.podcast-label > h1 {
	margin-bottom: 0;
	color: var(--secondary-label-color);
	margin-right: auto;
	margin-left: 20px;
	text-align: left;
}
#rss-feed-insert > form > #upload-form-top > i {
	margin-top: 0;
	color: var(--font-label-color);
	margin-left: 5%;
	text-align: left;
	width: 100%;
	display: block;
}

.new-rss-options {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
}
#new-rss-button {
	background: rgba(100, 228, 60, 0.27) !important;
	border: 3px solid var(--primary-color);
	border-radius: 25px;
	color: var(--secondary-label-color);
	font-size: 1.8em;
	font-weight: bold;
	padding: 10px;
	padding-left: 20px;
	padding-right: 20px;
	margin: 20px;
	cursor: pointer;
	margin-right: 20%;
	white-space: nowrap;
}

#new-rss-button-disabled {
	background-color: var(--font-label-color) !important;
	white-space: nowrap;
	border: 3px solid var(--font-label-color);
	color: white !important;
	opacity: 40%;
	border-radius: 100px;
	font-size: 1.8em;
	padding: 2px;
	padding-left: 20px;
	padding-right: 20px;
	margin: 20px;
	margin-right: 20%;
}

.rss-label-description {
	margin-bottom: 40px;
}

.rss-input-field {
	margin-bottom: 20px;
}

.iframe-code-container.rss {
	width: 60%;
	font-size: 1em;
	color: grey;
	height: fit-content;
	padding: 10px;
	margin-left: 10px;
	border: 2px solid var(--primary-color);
}

.row-container.rss-code {
	width: 70%;
	justify-content: flex-start;
	align-items: center;
	margin-right: auto;
	margin-left: 5%;
}
.row-container.rss-code > h2 {
	white-space: nowrap;
	color: var(--secondary-label-color);
}
.column.iframe-instructions.row-container {
	justify-content: flex-start;
	width: 90%;
	text-align: left;
	margin-top: 30px;
}
.column.iframe-instructions.row-container > * {
	margin: 10px;
}
#iframe-copy-button {
	white-space: nowrap;
}

.podcast-label {
	font-size: 1em;
	justify-content: flex-start;
	align-items: flex-end;
}
.podcast-label > h1 {
	margin-left: 10px !important;
	margin-right: 0 !important;
}
