#podcast-page {
  color: var(--font-label-color);
}
.cover-art {
  width: 50%;
  margin-right: 20px;
}

.cover-art > img {
  width: 80%;
}

.podcast-details {
  text-align: left;
  font-size: 1.15em;
  word-wrap: none;
}

.podcast-details > h3 {
  color: var(--secondary-label-color);
  font-size: 1.4em;
}

.podcast-details > #feed-url {
  color: var(--font-label-color);
  border: 3px solid var(--primary-color);
  padding: 15px;
  border-radius: 5px;
}

.podcast-documentation {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px;
}
.podcast-details > .row-container {
  justify-content: space-around;
}
.podcast-details > .row-container > .row-container > button {
  border: none;
  background-color: transparent;
  height: 10px;
  font-size: 1em;
  cursor: pointer;
}
#podcast-episodes-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px;
  margin-top: 90px;
}
#podcast-episodes-header > h1 {
  color: var(--secondary-label-color);
}

#podcast-description {
  height: 20%;

}

@media (max-width: 1440px) {
  .podcast-details > #feed-url {
    font-size: 1vw;
  }
}