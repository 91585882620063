/* #rss-feeds {

} */

.rss-title {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.rss-title > * {
  width: fit-content;
  color: var(--font-label-color);
}

.narrations-table {
  width: 75vw;
}
.narrations-table > * > tr, .narrations-table > * > ul > li > tr  {
  display: flex;
  flex-direction: row;
  border-color: var(--border-color);
  border-bottom-style: solid;
  border-bottom-width: 4px;
  /* border-radius: 10px; */
  margin-left: 1%;
  margin-right: 1%;
  margin-top: 0px;
  padding-top: 10px;
  /* cursor: pointer; */
  width: 75vw;
  justify-content: space-between;
}

.narrations-table > * > tr > *, .narrations-table > * > ul > li > tr > *{
  color: var(--font-label-color);
  font-size: 25px;
  text-align: center;
  width: 20%;
  overflow-x: hidden;
  white-space:nowrap;
  margin-top: auto;
  margin-bottom: auto;
}
.narrations-table > * > tr > .table-double, .narrations-table > * > ul > li > tr > .table-double {
  width: 40%;
  background-image: linear-gradient(to right, var(--font-label-color) 90%, white 100%);
  color: transparent;
  background-clip: text;
  text-align: left;
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
}

.rss-view-settings {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  color: var(--font-label-color);
  width: 30vw;
}
.rss-view-settings > h3 > span{
  width: 7vw;
  text-align: right;
}


.rss-voice {
  background: transparent;
  border: 3px solid var(--primary-color);
  padding: 10px;
  box-sizing: border-box;
  border-radius: 8px;
  color: var(--font-label-color);
  margin-left: 20px;
  text-align: center;
  width: 10vw;
}

.rss-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 75vw;
}

.rss-iframe {
  width: fit-content;
  text-align: left;
}

.articles-narrated{
  min-width: 220px;
}