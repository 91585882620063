/* #rss-view {

} */
.rss-view-voice {
  border: 3px solid rgba(150, 150, 150, 0.5);
  border-radius: 5px;
  width: 200px;
  height: fit-content;
  padding: 10px;
  margin-left: 20px;
}
.form-button.rss {
  font-size: 1.2em;
  border-radius: 7px;
  margin-left: auto;
  margin-right: 10%;
  margin-top: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.form-button.rss.disabled {
  border-color: var(--font-label-color);
  background-color: var(--font-label-color);
  color: white;
  -webkit-text-fill-color: white;
  opacity: 0.5;
}

.category-container {
  color: var(--font-label-color);
  justify-content: flex-start;
  align-items: center;
  width: 80%;
  height: 50px;
  margin: auto;
  margin-top: 100px !important;
  margin-bottom: 60px !important;
}

.category-container > h1 {
  margin: 0 !important;
}

.category-container > .column-container {
  margin-top: 25px;
}

.category-container > .column-container > .row-container {
  justify-content: flex-end;
  align-items: center;
}

.category-add-button {
  padding-left: 20px !important;
  padding-right: 20px !important;
  height: 40px !important;
}

.category-list {
  margin-bottom: 100px;
}

.category-arrow-container {
  /* position: absolute; */
  display: flex;
  flex-direction: column;
  left: 10%;
  transform: scale(1);
}


@media (max-width: 1280px)  {
  .category-list {
    margin-left: 6vw !important;
  }
  .category-icon {
    left: 16%;
  }
}