#invite-dialog-title{
  -webkit-appearance: none;
  text-align: center;
  margin-top: 12%;
  margin-bottom: 20px;
  color: #3F7045;
  font-family: 'Montserrat', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-size: 44px;
  font-weight: 700;
}
#invite-dialog-description{
  -webkit-appearance: none;
  text-align: center;
  font-size: 22px;
}
#invite-dialog-code{
  -webkit-appearance: none;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 40px;
  padding: 20px;
  /* background-color: rgb(255, 244, 229); */
  background-color: white;
  color: #3F7045;
  text-align: center;
  font-size: 27px;
  font-weight: bolder;
}

.chart-test{
  -webkit-appearance: none;
  background-color: purple;
}

.menu-icon {
  -webkit-appearance: none;
  position: fixed; /* Needs to stay visible for all mobile scrolling */
  display: flex;
  top: 5px;
  left: 10px;
  align-items: center;
  justify-content: center;
  background-color: #DADAE3;
  border-radius: 50%;
  z-index: 1;
  cursor: pointer;
  padding: 12px;
}

/* Give every child element its grid name */
.header {
  -webkit-appearance: none;
  grid-area: header;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  color: black;
  font-size: xx-large;
  font-weight: bold;
  margin-left:10px;
  margin-top: 10px;
  
}

.main {
  -webkit-appearance: none;
  grid-area: main;
  background-color: white;
}

.secondary {
  -webkit-appearance: none;
  grid-area: secondary;
}

.main-overview {
  -webkit-appearance: none;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(265px, 1fr));
  grid-auto-rows: 94px;
  grid-gap: 20px;
  margin: 20px; 
  background-color: white;
}

.overviewcard {
  -webkit-appearance: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: #d3d3;
}

.main-cards {
  -webkit-appearance: none;
  column-count: 1;
  column-gap: 20px;
  margin: 20px;
}

.footer {
  -webkit-appearance: none;
  grid-area: footer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  background-color: #648ca6;
}

.logout-div{
  -webkit-appearance: none;
  margin-right: 0;
}

.sidebar-title{
  -webkit-appearance: none;
  font-size: large;
  color: white;
}

#dash-app-bar{
  -webkit-appearance: none;
  margin-left: 120px;
  background-color: transparent;
  z-index: 13;
  height: 65px;
  box-shadow: none;
}

.dashboard-content-container{
  -webkit-appearance: none;
  background-color:white;
  width: 100%;
  height: 100vh;
  margin-left: 200px;
  margin-top: 85px;
  
}

@media (max-width: 1700px)  {
  .dashboard-content-container{
    -webkit-appearance: none;
    margin-left: 75px;
  }
}

@media (max-width: 1440px)  {
  .dashboard-content-container{
  -webkit-appearance: none;
  width: 100%;
  height: 100vh;
  margin-left: 200px;
  margin-right: 200px;
  margin-top: 85px;
  }
}

@media (max-width: 1440px)  {
  .dashboard-content-container{
  margin-left: 80px;
  }
}

#dashboard{
  -webkit-appearance: none;
  background-color:white;
  height: fit-content;
}

#blur-div {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  width: 100%;
  height: 35%;
  background-color: #ccc;
}

#under-construction{
  font-size: 30px;
  background-color:white;
  z-index: 100;
  position: relative;
  top: 190px;
  left: 110px;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 75%;
}
#build-icon{
  margin-left: 20px;
  font-size: 25px;
}

#mongoCharts {
  display: grid;
  justify-content: space-between;
  grid-template-columns: 15px 15px;
  grid-auto-rows: 30px;
  grid-gap: 35px;
  margin: 35px;
  display: flex;
  flex-flow: row wrap;
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  
}
#analysis-nlu-container {
  display: grid;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
  grid-gap: 35px;
  /* grid-template-columns: 50px 50px;
  grid-auto-rows: 30px;
  grid-gap: 35px;
  margin: 35px;
  display: flex;
  flex-flow: row wrap;
  max-width: 75rem;
  ; */
}
#about-ai {
  max-width: 75rem;

}

.modal-error-title{
  margin-top: 10%;
  margin-bottom: 10%;
}

.upgrade-button{
  font-family: var(--font-family);
  color: var(--secondary-label-color);
  background-color: transparent;
  border-color:  var(--primary-color);
  border-radius: 15px;
  font-size: 25px;
  font-weight: bold;
  padding: 20px 20px 20px 20px;
  margin: 25px;
  border-width: 5px;
  border-style: solid;
}
.upgrade-button:hover{
  cursor: pointer;
  color: white;
  background-color:var(--primary-color);
}