.home-page {
  all: unset;
  -webkit-appearance: none;
  display: flex;
  flex-direction: column;
  text-align: left;
  color: var(--font-label-color);
}

#home-page-info {
  all: unset;
  -webkit-appearance: none;
  width: 80%;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 54px;
  padding-right: 54px;
  border-radius: 5px;
  box-shadow: 4px 4px 20px 5px rgba(0, 0, 0, 0.23);
  text-align: center;
  -webkit-text-fill-color: var(--font-label-color);
}

.home-page-options {
  width: 274px;
  height: 70px;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 33px;
  padding: 13px;
  border-radius: 7px;
  border: solid 2px var(--primary-color);
  background-color: #DEFFCF;
  cursor: pointer;
}

.home-page-options > h2, .home-page-options > .row-container > h2 {
  -webkit-text-fill-color: var(--font-label-color);
  font-weight: bolder;
  font-size: 20px;
}

.pointer > span:hover, .pointer > span:active {
  font-size: 110%;
}

.home-page-options > span, .home-page-options > .row-container > span {
  -webkit-text-fill-color: var(--font-label-color);
  margin-top: -55px;
  margin-left: auto;
  font-size: 1.5em;
  opacity: 0.5;
}

.home-page > h1 {
  -webkit-text-fill-color: var(--font-label-color);
}

.column {
  -webkit-appearance: none;
  flex-direction: column;
}

.home-page-options.column {
  height: 320px;
  -webkit-text-fill-color: var(--font-label-color);
}
.home-page-options > h2 {
  -webkit-appearance: none;
  -webkit-text-fill-color: var(--font-label-color);
}

.home-page-options.column > .row-container {
  -webkit-appearance: none;
  width: 100%;
  align-items: center;
  margin-top: -10px;
}

.column > p {
  -webkit-appearance: none;
  text-align: left;
  width: 85%;
}

.column > h3 {
  -webkit-appearance: none;
  text-align: right;
  width: 100%;
  margin-top: auto;
}

.column > h3 > span {
  text-decoration: underline;
}


.home-page-info-arrow {
  -webkit-appearance: none;
  margin-left: auto;
  margin-top: 20px;
  font-size: 2em;
  cursor: pointer;
}

.home-page-icon {
  color: white;
  background-color: var(--primary-color);
  padding: 7px;
  margin-right: 10px;
  border-radius: 100%;
  font-size: 35px !important;
}


.narration-label {
  color: var(--secondary-label-color);
  font-weight: bolder;
}

.home-table {
  margin-left: 8%;
}

.home-table > * > tr  {
  width: 65vw;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
}

.new-button-container {
  width: 65vw;
  display: flex;
} 

.narration-tags {
  -webkit-text-fill-color: white;
}

.narration-play-icon > path:hover, .narration-play-icon > path:active {
  font-size: 115%;
}

.narration-play-icon:hover, .narration-play-icon:active {
  font-size: 70%;
}

.homepage-row{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.homepage-row-title{
  margin-left: 10px;
}