#new-button {
  padding: 10px;
  padding-left: 30px;
  padding-right: 30px;
  background: #DEFFCF;
  border: 3px solid var(--primary-color);
  border-radius: 200px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  margin-left: auto;
  color: var(--font-label-color);
  text-transform: none;
  -webkit-text-fill-color: #7B7B7B;


  
}
@media (max-width: 1440px)  {
  #new-button {
    padding: 8px;
    padding-left: 25px;
    padding-right: 25px;
    background: #DEFFCF;
    border: 3px solid var(--primary-color);
    border-radius: 175px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    margin-left: auto;
    color: var(--font-label-color);
    text-transform: none;

  }
}

#new-button-disabled {
  padding: 10px;
  padding-left: 25px;
  padding-right: 25px;
  background: var(--font-label-color);
  border: 3px solid var(--secondary-label-color);
  border-radius: 175px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  margin-left: auto;
  color: var(--secondary-label-color);
  text-transform: none;
  opacity: 0.5;
  cursor: default;
}

@media (max-width: 1440px)  {
  #new-button-disabled {
    padding: 8px;
    padding-left: 30px;
    padding-right: 30px;
    background: var(--font-label-color);
    border: 3px solid var(--secondary-label-color);
    border-radius: 200px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;
    margin-left: auto;
    color: var(--secondary-label-color);
    text-transform: none;
    opacity: 0.5;
    cursor: default;

  }
}

@media (max-width: 425px)  {
  #new-button {
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 14px;
  }
}

@media (max-width: 375px)  {
  #new-button {
    padding: 3.5px;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 12px;
  }
}