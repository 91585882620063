.lds-hourglass {
  all: unset;
  -webkit-appearance: none;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-hourglass:after {
  all: unset;
  -webkit-appearance: none;
  content: " ";
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 8px;
  box-sizing: border-box;
  border: 32px solid #fff;
  border-color: var(--primary-color) #E5E5E5 var(--primary-color) #E5E5E5;
  animation: lds-hourglass 1.2s infinite;
}
@keyframes lds-hourglass {
  
  0% {
    all: unset;
    -webkit-appearance: none;
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    all: unset;
    -webkit-appearance: none;
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    all: unset;
    -webkit-appearance: none;
    transform: rotate(1800deg);
  }
}

#spinner { 
  width: 100%px;
  height: 100%;
}