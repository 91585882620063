
.App {
  text-align: center;
  height:100vh;
  background-color: white;
  -webkit-background-color: white;
}
body {
  background-color: white !important;
}
.root {
  background-color:white;
  -webkit-background-color: white;
}

.cardStyle {
  background-color:white;
  -webkit-background-color: white;
}
.error-message-bar{
  min-height: 55px;
  font-size: 30px;
}

.dashboard-title{
  color: black;
  font-size: 44px;
  font-weight: 700;
  text-align: left;
}

.grid-container{
  height: 100%;
  padding-bottom: 3%;
}

.medium-title{
  font-family: 'Montserrat', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-size: 40px;
  font-weight: 700;
  color: var(--primary-color);
}


.small-title{
  font-family: 'Montserrat', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-size: 30px;
  font-weight: 700;
  color: var(--primary-color);
}

iframe{
  width: 500px;
  height: 250px;
}

.narrate, .narrateBig{
  background-color: var(--primary-color) !important;
}

.narrateBig{
  font-size: 1em !important;
}

.drop-zone-div{
  width: 900px;
}

.input-with-sample{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.pointer {
  cursor: pointer;
}