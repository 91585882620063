@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway&display=swap);
/* @import '~material-design-icons/iconfont/material-icons.css'; */

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.code-small{
  font-size: 1em;
}

.App {
  text-align: center;
  height:100vh;
  background-color: white;
  -webkit-background-color: white;
}
body {
  background-color: white !important;
}
.root {
  background-color:white;
  -webkit-background-color: white;
}

.cardStyle {
  background-color:white;
  -webkit-background-color: white;
}
.error-message-bar{
  min-height: 55px;
  font-size: 30px;
}

.dashboard-title{
  color: black;
  font-size: 44px;
  font-weight: 700;
  text-align: left;
}

.grid-container{
  height: 100%;
  padding-bottom: 3%;
}

.medium-title{
  font-family: 'Montserrat', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-size: 40px;
  font-weight: 700;
  color: var(--primary-color);
}


.small-title{
  font-family: 'Montserrat', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-size: 30px;
  font-weight: 700;
  color: var(--primary-color);
}

iframe{
  width: 500px;
  height: 250px;
}

.narrate, .narrateBig{
  background-color: var(--primary-color) !important;
}

.narrateBig{
  font-size: 1em !important;
}

.drop-zone-div{
  width: 900px;
}

.input-with-sample{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.pointer {
  cursor: pointer;
}
:root {
    --primary-color: #64E43C;
    --font-label-color: #7B7B7B;
    --secondary-label-color: #5F5E5E;
    --border-color: #b6b6b6;
    --disabled-text-color:  #C4C4C4;
    --free-tier-color: #6691FF;
    --pro-tier-color: var(--primary-color);
    --corporate-tier-color:#FFA92C;
    --enterprise-tier-color:#FF4141;
    --font-family: 'Montserrat', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen';
    --pricing-font-family:'Raleway', sans-serif;;
  }

.page {
  width: 75vw;
  background-color:white;
  -webkit-background-color: white;
  -moz-background-color: white;
  -o-background-color: white;
}

.comment-box{
  width: 500px;
  color: #7B7B7B;
  color: var(--font-label-color);
  background: none;
  outline: none;
}

.input-label{
    font-family: 'Montserrat', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen';
    font-family: var(--font-family);
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: #7B7B7B;
    color: var(--font-label-color);
    margin-right: 20px;
}

.form-text-large{
  font-family: 'Montserrat', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen';
  font-family: var(--font-family);
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  color: #7B7B7B;
  color: var(--font-label-color);
}

.form-text-medium-italic{
  font-family: 'Raleway', sans-serif;
  font-family: var(--pricing-font-family);
  font-weight: normal;
  font-size: 25px;
  font-style: italic;
  color: #7B7B7B;
  color: var(--font-label-color);

}

.form-button{
  font-family: 'Montserrat', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen';
  font-family: var(--font-family);
  font-style: normal;
  font-weight: bold;
  font-size: 1em;
  color: #7B7B7B;
  color: var(--font-label-color);
  background-color: white;
  border-radius: 3px;
  box-shadow: none;
  border-color: #64E43C;
  border-color: var(--primary-color);
  border-style: solid;
  padding: 12px 35px 12px 35px;
  outline: none;
}

.form-button:hover{
  cursor: pointer;
  background-color: #64E43C;
  background-color: var(--primary-color);
  color:white;
}

.close-button{
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 25px;
}

.close-button:hover{
  cursor: pointer;
  color:#64E43C;
  color:var(--primary-color);
}

.clickable-text-large{
  font-size: 27px;
  font-weight: 700;
  color:#64E43C;
  color:var(--primary-color);
}
.clickable-text-large:hover{
  color:#64E43C;
  color:var(--primary-color);
  cursor: pointer;
  text-decoration: underline;
}

.clickable-text-medium{
  font-size: 23px;
  font-weight: 700;
  color:#64E43C;
  color:var(--primary-color);
}
.text-medium-disabled{
  font-size: 23px;
  font-weight: 700;
  color:#C4C4C4;
  color:var(--disabled-text-color);
}

.clickable-text-medium:hover{
  color:#64E43C;
  color:var(--primary-color);
  cursor: pointer;
  text-decoration: underline;
}

.audio-select .MuiOutlinedInput-notchedOutline{
  border-width: 3px;
}

.audio-select{
  display: flex;
  flex-direction: row;
}

.text-medium{
  color:#64E43C;
  color:var(--primary-color);
  font-size: 23px;
  font-weight: bold;
}

#iframe-copy-button{
  all: unset;
  -webkit-appearance: none;
  border: 2px solid #64E43C;
  border: 2px solid var(--primary-color);
  color: #5F5E5E;
  color: var(--secondary-label-color);
  box-sizing: border-box;
  border-radius: 5px;
  padding: 8px;
  font-weight: bolder;
  font-size: 1.2em;
  margin-left: 20px;
}

.full-width {
  width: 100% !important;
}

.empty-space {
  margin-top: 100px;
  color: transparent;
}
.dzu-submitButton{
  display: none;
}

.horizontal-center {
  margin-left: auto !important;
  margin-right: auto !important;
}

.row-container {
  all: unset;
  -webkit-appearance: none;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-left: 10px;
  margin-right: 10px;
}

.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.align-center {
  align-items: center;
}
@media (max-width: 1440px) {
  .row-container {
      font-size: 0.9em;
      font-weight: bolder;
  } 
}
@media (max-width: 1440px)  {
  .page {
    margin-left: 5vw;
  }
}
@media (max-width: 425px)  {
  .section-title {
    font-size: 1em;
  }
}
.home-page{
  text-align: center;
  vertical-align: auto;
  height: 100vh;
 
}

#homepage-text{
  margin-top: 15%;
}

#home-signup{
  background-color: transparent;
  color: var(--primary-color);
  font-weight: 2000;
  font-size: 1em;
  border: 5px solid var(--primary-color);
  border-radius: 20px;
  box-shadow: none;
}

.separator {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  width: 100%;
  visibility: visible;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: xx-large;
  font-weight: 700;
}

.divider{
  width: 100%;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
}
.separator::before, .separator::after {
  content: '';
  flex: 1 1;
  border-bottom: 3px solid #000;
}
.separator::before {
  margin-right: .35em;
}
.separator::after {
  margin-left: .35em;
}

#login-page{
  height: 100vh;
}
#login-page-content{
  margin-top: 7%;
}
.paper {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.avatar {
  margin: 10px;
  background-color: red;
}
.form {
  width: 100%;
  margin-top: 10px
}
.submit {
  margin: 190px;
}

#signup-link{
  margin-top: 20px;
  font-size: 1.5em;
}

.google-login-container {
  text-align: center;
  display:table-cell;
  vertical-align:middle;
}
.lds-hourglass {
  all: unset;
  -webkit-appearance: none;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-hourglass:after {
  all: unset;
  -webkit-appearance: none;
  content: " ";
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 8px;
  box-sizing: border-box;
  border: 32px solid #fff;
  border-color: var(--primary-color) #E5E5E5 var(--primary-color) #E5E5E5;
  -webkit-animation: lds-hourglass 1.2s infinite;
          animation: lds-hourglass 1.2s infinite;
}
@-webkit-keyframes lds-hourglass {
  
  0% {
    all: unset;
    -webkit-appearance: none;
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    all: unset;
    -webkit-appearance: none;
    -webkit-transform: rotate(900deg);
            transform: rotate(900deg);
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    all: unset;
    -webkit-appearance: none;
    -webkit-transform: rotate(1800deg);
            transform: rotate(1800deg);
  }
}
@keyframes lds-hourglass {
  
  0% {
    all: unset;
    -webkit-appearance: none;
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    all: unset;
    -webkit-appearance: none;
    -webkit-transform: rotate(900deg);
            transform: rotate(900deg);
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    all: unset;
    -webkit-appearance: none;
    -webkit-transform: rotate(1800deg);
            transform: rotate(1800deg);
  }
}

#spinner { 
  width: 100%px;
  height: 100%;
}
#invite-dialog-title{
  -webkit-appearance: none;
  text-align: center;
  margin-top: 12%;
  margin-bottom: 20px;
  color: #3F7045;
  font-family: 'Montserrat', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-size: 44px;
  font-weight: 700;
}
#invite-dialog-description{
  -webkit-appearance: none;
  text-align: center;
  font-size: 22px;
}
#invite-dialog-code{
  -webkit-appearance: none;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 40px;
  padding: 20px;
  /* background-color: rgb(255, 244, 229); */
  background-color: white;
  color: #3F7045;
  text-align: center;
  font-size: 27px;
  font-weight: bolder;
}

.chart-test{
  -webkit-appearance: none;
  background-color: purple;
}

.menu-icon {
  -webkit-appearance: none;
  position: fixed; /* Needs to stay visible for all mobile scrolling */
  display: flex;
  top: 5px;
  left: 10px;
  align-items: center;
  justify-content: center;
  background-color: #DADAE3;
  border-radius: 50%;
  z-index: 1;
  cursor: pointer;
  padding: 12px;
}

/* Give every child element its grid name */
.header {
  -webkit-appearance: none;
  grid-area: header;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  color: black;
  font-size: xx-large;
  font-weight: bold;
  margin-left:10px;
  margin-top: 10px;
  
}

.main {
  -webkit-appearance: none;
  grid-area: main;
  background-color: white;
}

.secondary {
  -webkit-appearance: none;
  grid-area: secondary;
}

.main-overview {
  -webkit-appearance: none;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(265px, 1fr));
  grid-auto-rows: 94px;
  grid-gap: 20px;
  margin: 20px; 
  background-color: white;
}

.overviewcard {
  -webkit-appearance: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: #d3d3;
}

.main-cards {
  -webkit-appearance: none;
  -webkit-column-count: 1;
          column-count: 1;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  margin: 20px;
}

.footer {
  -webkit-appearance: none;
  grid-area: footer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  background-color: #648ca6;
}

.logout-div{
  -webkit-appearance: none;
  margin-right: 0;
}

.sidebar-title{
  -webkit-appearance: none;
  font-size: large;
  color: white;
}

#dash-app-bar{
  -webkit-appearance: none;
  margin-left: 120px;
  background-color: transparent;
  z-index: 13;
  height: 65px;
  box-shadow: none;
}

.dashboard-content-container{
  -webkit-appearance: none;
  background-color:white;
  width: 100%;
  height: 100vh;
  margin-left: 200px;
  margin-top: 85px;
  
}

@media (max-width: 1700px)  {
  .dashboard-content-container{
    -webkit-appearance: none;
    margin-left: 75px;
  }
}

@media (max-width: 1440px)  {
  .dashboard-content-container{
  -webkit-appearance: none;
  width: 100%;
  height: 100vh;
  margin-left: 200px;
  margin-right: 200px;
  margin-top: 85px;
  }
}

@media (max-width: 1440px)  {
  .dashboard-content-container{
  margin-left: 80px;
  }
}

#dashboard{
  -webkit-appearance: none;
  background-color:white;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

#blur-div {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  width: 100%;
  height: 35%;
  background-color: #ccc;
}

#under-construction{
  font-size: 30px;
  background-color:white;
  z-index: 100;
  position: relative;
  top: 190px;
  left: 110px;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 75%;
}
#build-icon{
  margin-left: 20px;
  font-size: 25px;
}

#mongoCharts {
  display: grid;
  justify-content: space-between;
  grid-template-columns: 15px 15px;
  grid-auto-rows: 30px;
  grid-gap: 35px;
  margin: 35px;
  display: flex;
  flex-flow: row wrap;
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  
}
#analysis-nlu-container {
  display: grid;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
  grid-gap: 35px;
  /* grid-template-columns: 50px 50px;
  grid-auto-rows: 30px;
  grid-gap: 35px;
  margin: 35px;
  display: flex;
  flex-flow: row wrap;
  max-width: 75rem;
  ; */
}
#about-ai {
  max-width: 75rem;

}

.modal-error-title{
  margin-top: 10%;
  margin-bottom: 10%;
}

.upgrade-button{
  font-family: var(--font-family);
  color: var(--secondary-label-color);
  background-color: transparent;
  border-color:  var(--primary-color);
  border-radius: 15px;
  font-size: 25px;
  font-weight: bold;
  padding: 20px 20px 20px 20px;
  margin: 25px;
  border-width: 5px;
  border-style: solid;
}
.upgrade-button:hover{
  cursor: pointer;
  color: white;
  background-color:var(--primary-color);
}
#professional-editing-page {
  display: flex;
  flex-direction: column;
  color: var(--font-label-color);
  align-items: flex-start;
}

.row-container.professional {
  justify-content: space-between;
  width: 100%;
}

.row-container.professional > * {
  width: 30%;
  margin: 1%;
}

.professional-comment-box {
  width: 80%;
}
.history-bar {
  display: flex;
  justify-content: flex-start;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 15px;
}

@media (max-width: 1440px)  {
  .history-bar {
    display: flex;
    justify-content: flex-start;
    font-size: 2em;
  }
}
.history-item {
  color: gray;
  margin-right: 10px;
  cursor: pointer;
  text-overflow: ellipsis;

}

.history-item:hover, .history-item:active {
  font-size: 85%;
}
.current-item {
  width: 25vw;
  white-space:nowrap;
  overflow: hidden;
  text-align: left;
  background-image: linear-gradient(to right, var(--primary-color) 93%, white 100%);
  color: transparent;
  -webkit-background-clip: text;
          background-clip: text;
  text-overflow: ellipsis;
  /* -webkit-text-fill-color: #64E43C; */

}

@media (max-width: 1440px)  {
  .current-item {
    width: 25vw;
    white-space:nowrap;
    overflow: hidden;
    text-align: left;
    -webkit-overflow-background-image: linear-gradient(to right, var(--primary-color) 90%, white 100%);
    color: transparent;
    -webkit-background-clip: text;
            background-clip: text;
    text-overflow: ellipsis;

    /* -webkit-text-fill-color: #64E43C; */

  }
  
}

@media (max-width: 768px)  {
  .history-bar {
    font-size: 1.5em;
  }
  .current-item {
    width: 40vw;
  }
}

@media (max-width: 425px)  {
  .history-bar {
    font-size: 1em;
  }
}

.current-item-podcast {
  white-space:nowrap;
  overflow: hidden;
  text-align: left;
  color: var(--primary-color);
  
}
.audio-btn{
    margin-left: 20px;
}

.audio-btn :hover{
    cursor: pointer;
}

.small-play-icon {
    cursor: pointer;
}
.audio-select {
  display: flex;
  flex-direction: row;
}

#new-button {
  padding: 10px;
  padding-left: 30px;
  padding-right: 30px;
  background: #DEFFCF;
  border: 3px solid var(--primary-color);
  border-radius: 200px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  margin-left: auto;
  color: var(--font-label-color);
  text-transform: none;
  -webkit-text-fill-color: #7B7B7B;


  
}
@media (max-width: 1440px)  {
  #new-button {
    padding: 8px;
    padding-left: 25px;
    padding-right: 25px;
    background: #DEFFCF;
    border: 3px solid var(--primary-color);
    border-radius: 175px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    margin-left: auto;
    color: var(--font-label-color);
    text-transform: none;

  }
}

#new-button-disabled {
  padding: 10px;
  padding-left: 25px;
  padding-right: 25px;
  background: var(--font-label-color);
  border: 3px solid var(--secondary-label-color);
  border-radius: 175px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  margin-left: auto;
  color: var(--secondary-label-color);
  text-transform: none;
  opacity: 0.5;
  cursor: default;
}

@media (max-width: 1440px)  {
  #new-button-disabled {
    padding: 8px;
    padding-left: 30px;
    padding-right: 30px;
    background: var(--font-label-color);
    border: 3px solid var(--secondary-label-color);
    border-radius: 200px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;
    margin-left: auto;
    color: var(--secondary-label-color);
    text-transform: none;
    opacity: 0.5;
    cursor: default;

  }
}

@media (max-width: 425px)  {
  #new-button {
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 14px;
  }
}

@media (max-width: 375px)  {
  #new-button {
    padding: 3.5px;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 12px;
  }
}
.popup-modal {
  min-height: 20vh;
  width: 10vw;
}

@media (max-width: 1440px)  {
  .popup-modal {
    min-height: 30vh;
    width: 15vw;
  }
}
#iframe-dialog-container{
  all: unset;
  -webkit-appearance: none;
  display: flex;
  flex-direction: column;;
}
.popup-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  color: gray;
  text-align: center;
}
.warning {
  color: var(--secondary-label-color);
  -webkit-text-fill-color: var(--secondary-label-color);

}
.warning-icon {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 17vh;
}
.warning-icon > * {
  position: absolute;
}

.warning-icon > .exclamation {
  margin-right: 25px;
}

#popup-content {
  display: flex;
  justify-content: flex-start;
}
#popup-content > button {
  border: none;
  background-color: transparent;
}
#popup-content > span {
  color:var(--primary-color);
}
#popup-content > .unSelected {
  color: var(--font-label-color)
}
#alert-dialog-title{
  color: var(--primary-color);
  font-family: 'Montserrat', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-size: 44px;
  font-weight: 700;
}
#alert-dialog-description{
  font-size: 22px;
}
.root {
  background-color:white;
  -webkit-background-color: white;
}

#file-info-button-container{
  all: unset;
  -webkit-appearance: none;
  display: flex;
  flex-direction: column;
  width: 100px;
}

.distribute-button {
  -webkit-appearance: none;
  border: none;
  background-color: transparent;
  font-size: 25px;
  cursor: pointer;
}

.delete-button {
  -webkit-appearance: none;
  border: none;
  background-color: transparent;
  color: rgb(255, 79, 79);
  font-size: 1.5em;
  cursor: pointer;
}

.deletePopup {
  -webkit-appearance: none;
  height: 50vh;
  width: 30vw;
}

.deletePopup > * {
  -webkit-appearance: none;
  color: rgb(255, 79, 79);
  text-align: center;
}


.delete-button-popup {
  -webkit-appearance: none;
  border: 3px solid #FF4141;
  border-radius: 10px;
  background-color: transparent;
  color: var(--font-label-color);
  font-size: 1.5em;
  cursor: pointer;
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
  z-index: 99;
  position: relative;
}

.podcastPopup {
  -webkit-appearance: none;
  width: 30vw;
}

.podcastPopup > * {
  -webkit-appearance: none;
  color: var(--primary-color);
  text-align: center;
}

button > .request-popup {
  -webkit-appearance: none;
  position: absolute;
  color: gray;
  padding: 5px;
  background-color: #E5E5E5;
}

.invisible {
  -webkit-appearance: none;
  position: absolute;
  opacity: 0;
}

.custom-star {
  -webkit-appearance: none;
  color: #ffde59;
  -webkit-filter: drop-shadow(2px 2px 0px #ff8828);
          filter: drop-shadow(2px 2px 0px #ff8828);
  font-size: 2em;
}

.narration-play-icon {
  -webkit-appearance: none;
  display: flex;
  flex-direction: row;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-overflow: ellipsis;
}

.narration-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 270px;
}

.narration-tags {
  -webkit-appearance: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.narration-tags > *{
  all: unset;
  -webkit-appearance: none;
  font-size: 0.5em;
  background-color: var(--font-label-color);
  border-radius: 100px;
  padding: 5px;
  margin: 2px;
  width: 60%;
  color: white;
  font-weight: bolder;
}

.podcast-episode {
  -webkit-appearance: none;
  background-color: #4875EA;
}

.custom-narration {
  -webkit-appearance: none;
  background-color: #FFCF0C;
}

.in-progress-custom {
  -webkit-appearance: none;
  background-color: #FF545E;
}

.search-bar{
  -webkit-appearance: none;
  width: 200px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 100px;
  border: 2px solid var(--font-label-color);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: auto;
}

.search-bar > svg{
  -webkit-appearance: none;
  padding: 5px;
  font-size: 2em !important;
  color: var(--font-label-color) !important;
}

.search-bar > input{
  -webkit-appearance: none;
  border: none;
  background-color: transparent;
  width: 100%;
  font-size: 1em;
  color: var(--font-label-color);
}

.search-bar > input:focus{
  -webkit-appearance: none;
  border: none;
  outline: none;
}

.play-icon-narration-list{
  position: relative;
  bottom: 5px;
  margin-right: 10px;
}
.narrations-table > thead {
  -webkit-text-fill-color: #7B7B7B;
}

.narrations-table > thead > tbody > .pointer > td{
  -webkit-text-fill-color: #7B7B7B;
}
@media (max-width: 1440px)  {
  .distribute-button{
    -webkit-appearance: none;
    font-size: 20px;
  }
  .narration-title {
    width: 210px;
  }
}

@media (max-width: 1024px)  {
  .narration-title {
    font-size: 0.86em;
    width: 130px;
  }
}

@media (max-width: 768px)  {
  .narration-title {
    width: 200px;
  }
}

@media (max-width: 425px)  {
  #file-list-page {
    overflow-x: hidden;
  }
  .narration-title {
    font-size: 0.42em;
    width: 50px;
  }
  .narration-tags > * {
    font-size: 0.4em;
    padding: 2px;
    width: 60%;
  }
  .search-bar {
    width: 100px;
  }
  .files-list-table {
    margin-left: -5% !important;
  }
  .files-list-table > * > .pointer {
    width: 105% !important;
  }
  .narration-date {
    font-size: 0.36em !important;
  }
}

@media (max-width: 375px)  {
  .narration-tags > * {
    font-size: 0.3em;
  }
  .search-bar {
    width: 100px;
  }
  .files-list-table {
    margin-left: -20% !important;
  }
  .narration-date {
    font-size: 0.36em !important;
  }
}
.table-double{
    background-color: red;
}

.row-float-bottom{
  margin-top: auto !important;
  margin-bottom: 0 !important;
}

.pointer {
    -webkit-text-fill-color: #7B7B7B;
}

.pointer > .i > {
    -webkit-text-fill-color: white;
}
.help-icon{
    width: 20px;
    height: 20px;
}

@media (max-width: 1440px)  {
    .file-info-page {
     
        width: 68vw;
        /* -webkit-text-fill-color: #7B7B7B; */
        
    }
}

#header > .row-container {
    /* all: unset;
  -webkit-appearance: none; */
font-size: 20px;

}

.iframe-container{
  all: unset;
  -webkit-appearance: none;
  margin: 30px;
}

#no-audio-text{
    font-size: 30px;
    margin-top: 20px;
}

@media (max-width: 1440px)  {
    #no-audio-text{
        font-size: 25px;
        margin-top: 25px;
    }
}

.file-info-distribution-options{
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.file-info-page > .file-info-distribution-options > .row-container {
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    justify-content: flex-start;
}

.file-info-option {
    color: var(--font-label-color);
    padding-left: 15px;
    padding-right: 15px;
    border-bottom: 5px solid var(--border-color); 
    cursor: pointer;
    
}

.file-info-option.filler {
  width: 35%;
}

.file-info-option.selected {
    color: var(--primary-color);
    border-color: var(--primary-color);
    -webkit-text-fill-color: #64E43C;

}

.file-options-container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    color: var(--font-label-color);
}


.iframe-code-container {
    border: 2px solid #c2c2c1;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 1.5em;
    width: 50%;
    white-space: nowrap;
    overflow-x: hidden;
    align-items: center;
    display: flex;
}
@media (max-width: 1440px)  {
    .iframe-code-container {
        border: 2px solid #c2c2c1;
        box-sizing: border-box;
        border-radius: 4px;
        font-size: 1em;
        width: 50%;
        white-space: nowrap;
        overflow-x: hidden;
        align-items: center;
        display: flex;
    }
    .file-info-option.filler {
      width: 25%;
    }
}

.download-button {
    border: 3px solid #64E43C;
    border-radius: 5px;
    padding: 10px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: auto;
    margin-left: auto;
    font-size: 1.5em;
    font-weight: bolder;
    color: var(--font-label-color);
    text-decoration: none;
    cursor: pointer;
}       
@media (max-width: 1440px)  {
    .download-button {
        border: 3px solid #64E43C;
        border-radius: 5px;
        padding: 10px;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: auto;
        margin-left: auto;
        font-size: 1em;
        font-weight: bolder;
        color: var(--font-label-color);
        text-decoration: none;
        cursor: pointer;
    }
  .publish-container {
    margin-left: -30px !important;
  }
}

.iframe-instructions {
  font-size: 1.35em;
}

@media (max-width: 1440px)  {
    .iframe-instructions {
        font-size: 1.1em;
      }
}

.row-container > .h2 {
    -webkit-text-fill-color: var(--primary-color);

}

.grid-container {
    margin-bottom: 50px;

}

.upload-icon {
  border-radius: 100%;
  width: 50px;
  height: 50px;
  font-size: 2.4em;
  color: var(--secondary-label-color);
  margin-top: 15px;
}
.new-rss-options > .row-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
}
.new-rss-options > .row-container > h2 {
	text-align: right;
	color: var(--secondary-label-color);
	margin-right: 20px;
	font-weight: bolder;
	width: 200px;
}
.new-rss-options > .row-container > * {
	width: auto;
}

.podcast-label > h1 {
	margin-bottom: 0;
	color: var(--secondary-label-color);
	margin-right: auto;
	margin-left: 20px;
	text-align: left;
}
#rss-feed-insert > form > #upload-form-top > i {
	margin-top: 0;
	color: var(--font-label-color);
	margin-left: 5%;
	text-align: left;
	width: 100%;
	display: block;
}

.new-rss-options {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
}
#new-rss-button {
	background: rgba(100, 228, 60, 0.27) !important;
	border: 3px solid var(--primary-color);
	border-radius: 25px;
	color: var(--secondary-label-color);
	font-size: 1.8em;
	font-weight: bold;
	padding: 10px;
	padding-left: 20px;
	padding-right: 20px;
	margin: 20px;
	cursor: pointer;
	margin-right: 20%;
	white-space: nowrap;
}

#new-rss-button-disabled {
	background-color: var(--font-label-color) !important;
	white-space: nowrap;
	border: 3px solid var(--font-label-color);
	color: white !important;
	opacity: 40%;
	border-radius: 100px;
	font-size: 1.8em;
	padding: 2px;
	padding-left: 20px;
	padding-right: 20px;
	margin: 20px;
	margin-right: 20%;
}

.rss-label-description {
	margin-bottom: 40px;
}

.rss-input-field {
	margin-bottom: 20px;
}

.iframe-code-container.rss {
	width: 60%;
	font-size: 1em;
	color: grey;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	padding: 10px;
	margin-left: 10px;
	border: 2px solid var(--primary-color);
}

.row-container.rss-code {
	width: 70%;
	justify-content: flex-start;
	align-items: center;
	margin-right: auto;
	margin-left: 5%;
}
.row-container.rss-code > h2 {
	white-space: nowrap;
	color: var(--secondary-label-color);
}
.column.iframe-instructions.row-container {
	justify-content: flex-start;
	width: 90%;
	text-align: left;
	margin-top: 30px;
}
.column.iframe-instructions.row-container > * {
	margin: 10px;
}
#iframe-copy-button {
	white-space: nowrap;
}

.podcast-label {
	font-size: 1em;
	justify-content: flex-start;
	align-items: flex-end;
}
.podcast-label > h1 {
	margin-left: 10px !important;
	margin-right: 0 !important;
}

/* #rss-feeds {

} */

.rss-title {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.rss-title > * {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: var(--font-label-color);
}

.narrations-table {
  width: 75vw;
}
.narrations-table > * > tr, .narrations-table > * > ul > li > tr  {
  display: flex;
  flex-direction: row;
  border-color: var(--border-color);
  border-bottom-style: solid;
  border-bottom-width: 4px;
  /* border-radius: 10px; */
  margin-left: 1%;
  margin-right: 1%;
  margin-top: 0px;
  padding-top: 10px;
  /* cursor: pointer; */
  width: 75vw;
  justify-content: space-between;
}

.narrations-table > * > tr > *, .narrations-table > * > ul > li > tr > *{
  color: var(--font-label-color);
  font-size: 25px;
  text-align: center;
  width: 20%;
  overflow-x: hidden;
  white-space:nowrap;
  margin-top: auto;
  margin-bottom: auto;
}
.narrations-table > * > tr > .table-double, .narrations-table > * > ul > li > tr > .table-double {
  width: 40%;
  background-image: linear-gradient(to right, var(--font-label-color) 90%, white 100%);
  color: transparent;
  -webkit-background-clip: text;
          background-clip: text;
  text-align: left;
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
}

.rss-view-settings {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  color: var(--font-label-color);
  width: 30vw;
}
.rss-view-settings > h3 > span{
  width: 7vw;
  text-align: right;
}


.rss-voice {
  background: transparent;
  border: 3px solid var(--primary-color);
  padding: 10px;
  box-sizing: border-box;
  border-radius: 8px;
  color: var(--font-label-color);
  margin-left: 20px;
  text-align: center;
  width: 10vw;
}

.rss-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 75vw;
}

.rss-iframe {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-align: left;
}

.articles-narrated{
  min-width: 220px;
}
/* #rss-view {

} */
.rss-view-voice {
  border: 3px solid rgba(150, 150, 150, 0.5);
  border-radius: 5px;
  width: 200px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 10px;
  margin-left: 20px;
}
.form-button.rss {
  font-size: 1.2em;
  border-radius: 7px;
  margin-left: auto;
  margin-right: 10%;
  margin-top: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.form-button.rss.disabled {
  border-color: var(--font-label-color);
  background-color: var(--font-label-color);
  color: white;
  -webkit-text-fill-color: white;
  opacity: 0.5;
}

.category-container {
  color: var(--font-label-color);
  justify-content: flex-start;
  align-items: center;
  width: 80%;
  height: 50px;
  margin: auto;
  margin-top: 100px !important;
  margin-bottom: 60px !important;
}

.category-container > h1 {
  margin: 0 !important;
}

.category-container > .column-container {
  margin-top: 25px;
}

.category-container > .column-container > .row-container {
  justify-content: flex-end;
  align-items: center;
}

.category-add-button {
  padding-left: 20px !important;
  padding-right: 20px !important;
  height: 40px !important;
}

.category-list {
  margin-bottom: 100px;
}

.category-arrow-container {
  /* position: absolute; */
  display: flex;
  flex-direction: column;
  left: 10%;
  -webkit-transform: scale(1);
          transform: scale(1);
}


@media (max-width: 1280px)  {
  .category-list {
    margin-left: 6vw !important;
  }
  .category-icon {
    left: 16%;
  }
}
/* #new-podcast-page {

} */

.podcast-form {
  width: 100%;
  text-align: left;
  color: var(--font-label-color);
}

.podcast-title {
  color: var(--secondary-label-color);
}
.image-dropzone {
  display: flex;
  flex-direction: column;
}
.image-dropzone > img {
  border: 2px solid var(--secondary-label-color);
  max-width: 20vw;
  max-height: 20vw;
}
.podcast-description {
  width: 75%;
}
.container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-right: 15px;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: 3px solid var(--primary-color);
  }
  
  /* On mouse-over, add a grey background color */
  /* .container:hover input ~ .checkmark {
    background-color: silver;
  }
   */
  .container input:checked ~ .checkmark {
    background-color: var(--primary-color)
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
#podcast-page {
  color: var(--font-label-color);
}
.cover-art {
  width: 50%;
  margin-right: 20px;
}

.cover-art > img {
  width: 80%;
}

.podcast-details {
  text-align: left;
  font-size: 1.15em;
  word-wrap: none;
}

.podcast-details > h3 {
  color: var(--secondary-label-color);
  font-size: 1.4em;
}

.podcast-details > #feed-url {
  color: var(--font-label-color);
  border: 3px solid var(--primary-color);
  padding: 15px;
  border-radius: 5px;
}

.podcast-documentation {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px;
}
.podcast-details > .row-container {
  justify-content: space-around;
}
.podcast-details > .row-container > .row-container > button {
  border: none;
  background-color: transparent;
  height: 10px;
  font-size: 1em;
  cursor: pointer;
}
#podcast-episodes-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px;
  margin-top: 90px;
}
#podcast-episodes-header > h1 {
  color: var(--secondary-label-color);
}

#podcast-description {
  height: 20%;

}

@media (max-width: 1440px) {
  .podcast-details > #feed-url {
    font-size: 1vw;
  }
}
#narrations-list{
  max-height: 400px;
}

#side-bar-content{
  margin-top: 10px;
}

#side-bar-accordion{
  
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  border-color: white;
  border-width: 2px;
  outline-color: transparent;

  /* transition: 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 0ms; */
}

#analytics-button{
  padding: 0;
  box-shadow: none;

}

#expand-analytics-button{
  margin-right: 15px;
}

/* #add-icon{
  color:#87287c;
}
#file-icon{
  color: #3F5270;
}
#analytics-icon{
  color: #3F7045;
}

#invite-icon{
  color: #b44444;
} */

.sidebar-icon, .side-bar-button-text {
  color: var(--font-label-color) !important;
  font-size: 3em !important;
  margin-bottom: 10px;
  margin-top: 10px;
  font-weight: bolder;
}

.icon {
  color: var(--primary-color) !important;
  -webkit-text-fill-color: var(--primary-color) !important;
}
#sidebar-title {
  color: var(--font-label-color);
  font-family: Montserrat;
}
#side-bar-logo {
  all: unset;
  -webkit-appearance: none;
  -webkit-height: 50px;
  -moz-height: 50px;
  -o-height:50px;
  -ms-height:50px;
  height: 50px;
  padding: 20px;
  padding-left: 5px;
  -webkit-transform: rotate(25deg);
          transform: rotate(25deg);
}
.side-bar-help-button {
  /* margin-top: 40vh !important; */
  position: fixed !important;
  bottom: 0;
}
.side-bar-help-button > a {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
}
@media (max-width: 1440px)  {
  #side-bar-logo {
    padding-left: 0px;
  }
}

@media (max-width: 425px)  {
  #side-bar-logo {
    height: 45px;
    padding-left: -5px;
  }
  .sidebar-icon, .side-bar-button-text {
    color: var(--font-label-color) !important;
    font-size: 2.5em !important;
    margin-bottom: 10px;
    margin-top: 10px;
    font-weight: bolder;
  }
  .side-bar-help-button {
    /* margin-top: 40vh !important; */
    position: absolute !important;
    margin-left: -10px;
  }
}
#side-bar{
    position: fixed;
    height: 100vh;
    z-index: 120;
    box-shadow: 2px 2px 5px grey;
    position: 'relative';
    white-space: 'nowrap';
    /* padding-top: 65px; */
}

#side-bar-toggle{
    display: none;
    transition: -webkit-transform 0.4s ease-out;
    transition: transform 0.4s ease-out;
    transition: transform 0.4s ease-out, -webkit-transform 0.4s ease-out;
    width: 50px;
    height: 50px;
    background-color:transparent;
    margin-top: 5px;
    margin-left: auto;
    margin-right: auto;
    border-color: transparent;
    outline: none;
    border-radius: 15px;
}

#side-bar-toggle:hover {
    background-color:rgba(128, 128, 128, 0.164);
    cursor:pointer;
  }

@media (max-width: 1700px)  {
    #side-bar-toggle{
        display: block;
        border: none;

    }
}

.open{
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}


.settings-page {
  color: var(--font-label-color);
  text-align: left;
  padding-bottom: 75px;
}
.underline {
  border-top: 3px solid var(--font-label-color);
  width: 100%;
  margin-top: 22px;
}

.settings-icon {
  background-color: var(--secondary-label-color) !important;
  border-radius: 100%;
  font-size: 50px !important;
  padding: 5px;
  color: white !important;
}
.settings-section {
  display: flex;
  margin-top: 20px;
}
.settings-section > main {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}
.settings-section > main > .row-container {
  align-items: center;
  justify-content: flex-start;
}
.settings-section > main > .row-container > * {
  align-items: center;
}
.settings-button {
  color: var(--font-label-color);
  margin: 20px !important;
  cursor: pointer;
}
#no-podcast {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.subscription-link{
  margin: 30px;
  color: var(--primary-color);
  -webkit-text-fill-color: var(--primary-color);
}

.horizontal-row-settings-page{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.settings-page-medium-title{
  margin-right: 20px;
  font-size: 20px;
  font-weight: normal;
}

.clickable-text-medium {
  color: var(--primary-color);
  -webkit-text-fill-color: var(--primary-color);
}

.settings-page-info-text{
  font-family: var(--font-family);
  font-weight: normal;
  font-size: 20px;
}

#plus-button{
  color: var(--secondary-label-color) !important;
}

.podcast-info-text{
  position: relative;
  right: 18px;
}

.align-to-right{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
}

.vertical-flex-box-settings-page{
  display: inline-flex;
  flex-direction: column;
}

.settings-page-icon-title{
  display: flex;
  position: relative;
  right: 10px;
  align-items: center;
  flex-direction: row;
}
.setting-section-label{
  margin-left: 10px;
}

.icon-positioning{
  position: relative;
  top: 5px;
}

.clickable-embedded-text{
  color: red;
  font-weight: normal;
}
.clickable-embedded-text:hover{
  cursor: pointer;
  font-weight: bolder;
}
@media (max-width: 425px){
  .settings-section > main {
    margin-left: -40px;
  }
  .settings-section > main > h1 {
    margin-left: 40px;
  }
  .settings-page-info-text {
    font-size: 1em;
  }
}
.home-page {
  all: unset;
  -webkit-appearance: none;
  display: flex;
  flex-direction: column;
  text-align: left;
  color: var(--font-label-color);
}

#home-page-info {
  all: unset;
  -webkit-appearance: none;
  width: 80%;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 54px;
  padding-right: 54px;
  border-radius: 5px;
  box-shadow: 4px 4px 20px 5px rgba(0, 0, 0, 0.23);
  text-align: center;
  -webkit-text-fill-color: var(--font-label-color);
}

.home-page-options {
  width: 274px;
  height: 70px;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 33px;
  padding: 13px;
  border-radius: 7px;
  border: solid 2px var(--primary-color);
  background-color: #DEFFCF;
  cursor: pointer;
}

.home-page-options > h2, .home-page-options > .row-container > h2 {
  -webkit-text-fill-color: var(--font-label-color);
  font-weight: bolder;
  font-size: 20px;
}

.pointer > span:hover, .pointer > span:active {
  font-size: 110%;
}

.home-page-options > span, .home-page-options > .row-container > span {
  -webkit-text-fill-color: var(--font-label-color);
  margin-top: -55px;
  margin-left: auto;
  font-size: 1.5em;
  opacity: 0.5;
}

.home-page > h1 {
  -webkit-text-fill-color: var(--font-label-color);
}

.column {
  -webkit-appearance: none;
  flex-direction: column;
}

.home-page-options.column {
  height: 320px;
  -webkit-text-fill-color: var(--font-label-color);
}
.home-page-options > h2 {
  -webkit-appearance: none;
  -webkit-text-fill-color: var(--font-label-color);
}

.home-page-options.column > .row-container {
  -webkit-appearance: none;
  width: 100%;
  align-items: center;
  margin-top: -10px;
}

.column > p {
  -webkit-appearance: none;
  text-align: left;
  width: 85%;
}

.column > h3 {
  -webkit-appearance: none;
  text-align: right;
  width: 100%;
  margin-top: auto;
}

.column > h3 > span {
  text-decoration: underline;
}


.home-page-info-arrow {
  -webkit-appearance: none;
  margin-left: auto;
  margin-top: 20px;
  font-size: 2em;
  cursor: pointer;
}

.home-page-icon {
  color: white;
  background-color: var(--primary-color);
  padding: 7px;
  margin-right: 10px;
  border-radius: 100%;
  font-size: 35px !important;
}


.narration-label {
  color: var(--secondary-label-color);
  font-weight: bolder;
}

.home-table {
  margin-left: 8%;
}

.home-table > * > tr  {
  width: 65vw;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
}

.new-button-container {
  width: 65vw;
  display: flex;
} 

.narration-tags {
  -webkit-text-fill-color: white;
}

.narration-play-icon > path:hover, .narration-play-icon > path:active {
  font-size: 115%;
}

.narration-play-icon:hover, .narration-play-icon:active {
  font-size: 70%;
}

.homepage-row{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.homepage-row-title{
  margin-left: 10px;
}
/*  */
/* Laptop-L */
/*  */
@media (max-width: 1440px)  {
  .home-page {
    margin-left: 150px !important;
  }

  #home-page-info {
    margin: 15px;
    padding-left: 34px;
    padding-right: 34px;
    border-radius: 5px;
  }

  .home-page-options {
    width: 200px;
    height: 50px;
    margin: 20px;
    padding: 10px;
    border-radius: 5px;
  }

  .home-page-options > h2, .home-page-options > .row-container > h2 {
    font-size: 12px;
  }

  .home-page-options > span, .home-page-options > .row-container > span {
    margin-top: -35px;
  }

  .home-page > h1 {
    font-size: 1.5em;
  }

  .home-page-options.column {
    height: 290px;
    font-size: 11px;
  }

  .home-page-info-arrow {
    margin-top: 20px;
    font-size: 1.5em;
  }

  .narration-label {
    font-size: 20px 
  }

  .narrations-table {
    width: 200px;
    height: 100%;
    margin: 10px;
    padding-left: 0px;
    padding-right: 55px;
  }

  .feeds-table > .pointer > td {
    /* all: unset; */
    -webkit-appearance: none;
    font-size: 20px;
  }
}

/*  */
/* Tablet */
/*  */
@media (max-width: 768px)  {
  .home-page {
    width: 75vw;
    margin-left: 0px !important;
  }
  #home-page-info {
    width: 100%;
  }

  .home-page-options {
    width: 120px;
    height: 25px;
    padding: 10px;
    margin: 5px;
  }

  .home-page-options.column {
    height: 220px;
  }

  .home-page-options > p, .home-page-options > h3 {
    font-size: 0.8em;
  }
  .home-page-options > span, .home-page-options > .row-container > span {
    margin-top: -25px;
    font-size: 1em;
  }

  .home-page-options > h2, .home-page-options > .row-container > h2 {
    font-size: 10px;
  }

  .narrations-table {
    padding-left: 55px;
  }

  .narrations-table > * > .pointer {
    -webkit-appearance: none;
    font-size: 20px;
    width: 78vw;
    margin-left: -5vw;
  }

  .home-page-icon {
    padding: 4px;
    margin-right: 10px;
    border-radius: 100%;
    font-size: 15px !important;
  }
}

/*  */
/* Mobile-L */
/*  */
@media (max-width: 425px)  {
  .home-page {
    width: 60vw;
    font-size: 0.7em;
  }
  #home-page-info {
    font-size: 0.65em;
    width: 90%;
    margin-left: 0;
  }

  .home-page-options {
    width: 70px;
    height: 25px;
    padding: 5px;
    margin: 5px;
  }

  .home-page-options.column {
    height: 220px;
  }

  .home-page-options > p, .home-page-options > h3 {
    font-size: 0.6em;
  }
  .home-page-options > span, .home-page-options > .row-container > span {
    margin-top: -25px;
    font-size: 1em;
  }

  .home-page-options > h2, .home-page-options > .row-container > h2 {
    font-size: 6px;
  }

  .home-page-icon {
    padding: 4px;
    margin-right: 10px;
    border-radius: 100%;
    font-size: 15px !important;
  }

  .home-table  {
    margin-left: -10px;
  }

  .narration-label {
    font-size: 0.5em;
  }
  
  .narrations-table > * > .pointer {
    width: 61vw;
    margin-left: -7vw;
  }
}

/*  */
/* Mobile-M */
/*  */
@media (max-width: 375px)  {
  #home-page-info {
    font-size: 0.65em;
  }

  .home-page-options {
    width: 60px;
  }

  .home-page-options > span, .home-page-options > .row-container > span {
    margin-top: -25px;
    font-size: 1.3em;
  }

  .home-page-options > p, .home-page-options > h3 {
    font-size: 0.55em;
  }

  .home-page-options > h2, .home-page-options > .row-container > h2 {
    font-size: 5px;
  }

  .home-page-icon {
    font-size: 15px !important;
  }

  .home-table  {
    margin-left: -50px;
  }

  .narration-label {
    font-size: 0.4em;
  }
  .narrations-table > * > .pointer {
    margin-left: 0vw;
  }
}
#analytics-page {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.time-button {
	margin-bottom: 20px;
}
.charts-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	width: 100%;
	height: 500px;
	margin-bottom: 30px;
}
.charts-container > * {
	width: 100%;
	height: 100%;
}
.counts {
	height: 300px;
}
.counts > div > div {
	height: 200px;
	margin-bottom: -15px;
}
.dataVisualChart > * {
	margin-left: auto;
	margin-right: auto;
	height: auto;
}
.countChartContainer {
	height: 100;
	margin-bottom: 30px;
}
.countPercentChange {
	display: flex;
	justify-content: center;
	align-items: center;
}
.percentArrowBox {
	width: 25px;
	height: 25px;
	margin-left: 10px;
	background-color: rgb(40, 40, 40);
	border-radius: 5px;
}
.celebrationIcon {
	width: 30px !important;
	height: 30px !important;
	margin-top: 10px !important;
}
.celebrationIcon > * {
	width: 100%;
	height: 100%;
	margin-top: 10px !important;
}
.percentArrowBox > * {
	width: 25px;
	height: 25px;
	box-sizing: unset;
}

.averageChart > h1 {
	font-size: 4.5em;
	font-weight: 400;
}
.celebration-tooltip {
	display: "flex" !important;
	justify-content: "center" !important;
	align-items: "center" !important;
	height: "15%" !important;
}

@media only screen and (max-width: 1280px) {
	.charts-container.top {
		flex-direction: column;
		height: 750px;
	}
}

@media only screen and (max-width: 1024px) {
	.charts-container {
		flex-direction: column;
		width: 120%;
	}
	.charts-container.middle {
		height: 1000px;
	}
	.dataVisualChart > * {
		margin-left: auto;
		margin-right: 0;
	}
	.counts {
		flex-direction: row;
		height: 200px;
	}
}

.chip {
	display: "flex";
	justify-content: "center";
	align-items: "center";
	height: "15%" !important;
}

.instant-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 44%;
  margin: 3%;
  color: var(--secondary-label-color);
}
#video-button {
	color: white;
	border: 1px solid white;
	font-size: 0.875rem;
}
#dash-app-bar {
	position: absolute;
}


.product-button {
  font-family: var(--pricing-font-family);
  font-weight: bold;
  font-size: 15px;
  background-color: rgba(0, 0, 0, 0.212);
  border-radius: 10px;
  padding: 5px 15px 5px 15px;
  margin-bottom: 20px;
}
.product-button:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.466);
}

.product-button-disabled {
  font-family: var(--pricing-font-family);
  font-weight: bold;
  font-size: 19px;
  border-radius: 10px;
  padding: 5px 15px 5px 15px;
  border-style: solid;
}

a.stripe-link {
  text-decoration: none;
  font-family: var(--pricing-font-family);
  color: white;
}

.tier-card{
    width: 252px;
    height: 275px;
    font-size: 60px;
    color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding-bottom: 10px;
}

.free-tier{
    background-color: var(--free-tier-color);
}
.pro-tier{
    background-color: var(--pro-tier-color);
}
.corporate-tier{
    background-color: var(--corporate-tier-color);
}
.enterprise-tier{
    background-color: var(--enterprise-tier-color);
}

.tier-card-title{
    margin-top: 15px;
    font-size: 27px;
    font-weight: bold;
}

.tier-card-price{
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    font-size: 75px;
}

.monthly-text{
    font-family: 'Raleway', sans-serif;
    margin-top: 35px;
    font-size: 17px;
    font-weight: bold;
}
.price-container{
    display: flex;
    align-items: center;
}

.tier-info{
    font-family: 'Raleway', sans-serif;
    font-size: 12px;
    font-weight: normal;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    min-height: 20px;
}

.tier-card-big-text{
    font-family: var(--pricing-font-family);
    font-weight: bold;
    font-size: 30px;
}
.contact-email-message{
    margin-top: 20px;
}

.contact-sales-text{
    margin-top: 40px;
}
.tiers-container {
    margin-top: 50px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: row;
}

.flex-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 275px;
}
@media (max-width: 1600px)  {
    .pricing-modal{
        min-width: 85vw;
    }

}
@media (min-width: 1700px)  {
    .flex-column {
        margin-left: 25px;
        margin-right: 25px;
    }
}

.tier-list-title {
    font-family: var(--pricing-font-family);
    font-size: 20px;
    font-weight: bold;
}

.free-tier-color {
    color: var(--free-tier-color)
}

.pro-tier-color {
    color: var(--pro-tier-color);
}

.corporate-tier-color {
    color: var(--corporate-tier-color);
}
.enterprise-tier-color{
    color: var(--enterprise-tier-color);
}
.tier-list ul,
.tier-list li {
    text-align: left;
    margin: 0;
    padding: 0;
    list-style-type: disc;
}

.tier-list {
    color: var(--font-label-color);
    font-family: var(--pricing-font-family);
    font-size: 15px;
    max-width: 270px;
    padding-right: 10px;
}

.corporate-tier-color ul li::marker {
    color: var(--corporate-tier-color);
    font-size: 1.25em;
}

.free-tier-color ul li::marker {
    color: var(--free-tier-color);
    font-size: 1.25em;
}

.pro-tier-color ul li::marker {
    color: var(--pro-tier-color);
    font-size: 1.25em;
}

.enterprise-tier-color ul li::marker {
    color: var(--enterprise-tier-color);
    font-size: 1.25em;
}

.pricing-modal{
    font-family: var(--pricing-font-family);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70vw;
}
.interval-selector{
    margin-top: 20px;
}
.tier-list-sub-title{
    font-family: var(--pricing-font-family);
    font-size: 17px;
    font-weight: normal;
}

.pricing-close-button{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 10px;
    position: absolute;
    right: 0;
}
.pricing-close-button:hover{
    cursor: pointer;
}

.text-align-left-pricing-info{
    margin-top: 10px;
    text-align: left;
}


.FormRow {
  border-bottom: 1px solid grey;
  text-align: center;
  width: 80%;
}
.card-holder{
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElement {
  width: 100%;
  padding: 11px 15px 11px 0;
  border: 1px solid #eee;
}

.error-text{
  color: red
}

.error-text{
    color: red
  }

.successful-payment{
  display: flex;
  justify-content: center;
  align-items: center;
}
.success-message{
  padding: 200px;
  font-size: 40px;
}
.payment-form-message{
  font-size: 30px;
  padding: 10px;
}
.checkout-container {
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
  }

.form-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  height: 100%;
}

.item-section {
  width: 50%;
  height: 100%;
}

.checkout-content{
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.coupon-section{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 350px;
  text-align: center;
}

.coupon-message{
  position: absolute;
  height: 50px;
  border-radius: 20px;

}

.message-spacer{
  visibility: hidden;
  height: 90px;
  background-color: purple;
}

.percent-off{
  padding: 15px;
  background-color: rgb(122, 223, 34);
  z-index: 2;
}

.discount{
  display: flex;
  flex-direction: column;
  z-index: 999;
}

.coupon-button{
  margin-top: 15px;
  background-color: royalblue;
  padding: 10px;
  font-size: 17px;
  color: white;
  border-radius: 10px;
}

.coupon-button:hover{
  cursor: pointer;
  background-color: rgb(96, 128, 224);
}
.separator {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  width: 100%;
  visibility: visible;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: xx-large;
  font-weight: 700;
}

.divider{
  width: 100%;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
}
.separator::before, .separator::after {
  content: '';
  flex: 1 1;
  border-bottom: 3px solid #000;
}
.separator::before {
  margin-right: .35em;
}
.separator::after {
  margin-left: .35em;
}

#login-page{
  height: 100vh;
}
#login-page-content{
  margin-top: 7%;
}
.paper {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.avatar {
  margin: 10px;
  background-color: red;
}
.form {
  width: 100%;
  margin-top: 10px
}
.submit {
  margin: 190px;
}

#signup-link{
  margin-top: 20px;
  font-size: 1.5em;
}

.google-login-container {
  text-align: center;
  display:table-cell;
  vertical-align:middle;
}
.signup-btn{
    color: var(--primary-color);;
    background-color: transparent;
    border-color:  var(--primary-color);;
    border-radius: 60px;
    font-size: 20px;
    padding-left: 25px;
    padding-right: 25px;
    margin: 25px;
    border-style: solid;
  }

  .verify-btn{
    color: white;
    background-color:  var(--primary-color);;
    border-color:  var(--primary-color);;
    border-width: 10px;
    border-radius: 60px;
    font-size: 50px;
    padding-left: 25px;
    padding-right: 25px;
    margin: 25px;
    border-style: solid;
  }
  
  .verify-btn:hover{
    cursor: pointer;
  
    background: white;
    color:var(--primary-color);;
  
  }
