@media (max-width: 1440px)  {
  .distribute-button{
    -webkit-appearance: none;
    font-size: 20px;
  }
  .narration-title {
    width: 210px;
  }
}

@media (max-width: 1024px)  {
  .narration-title {
    font-size: 0.86em;
    width: 130px;
  }
}

@media (max-width: 768px)  {
  .narration-title {
    width: 200px;
  }
}

@media (max-width: 425px)  {
  #file-list-page {
    overflow-x: hidden;
  }
  .narration-title {
    font-size: 0.42em;
    width: 50px;
  }
  .narration-tags > * {
    font-size: 0.4em;
    padding: 2px;
    width: 60%;
  }
  .search-bar {
    width: 100px;
  }
  .files-list-table {
    margin-left: -5% !important;
  }
  .files-list-table > * > .pointer {
    width: 105% !important;
  }
  .narration-date {
    font-size: 0.36em !important;
  }
}

@media (max-width: 375px)  {
  .narration-tags > * {
    font-size: 0.3em;
  }
  .search-bar {
    width: 100px;
  }
  .files-list-table {
    margin-left: -20% !important;
  }
  .narration-date {
    font-size: 0.36em !important;
  }
}