@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');
:root {
    --primary-color: #64E43C;
    --font-label-color: #7B7B7B;
    --secondary-label-color: #5F5E5E;
    --border-color: #b6b6b6;
    --disabled-text-color:  #C4C4C4;
    --free-tier-color: #6691FF;
    --pro-tier-color: var(--primary-color);
    --corporate-tier-color:#FFA92C;
    --enterprise-tier-color:#FF4141;
    --font-family: 'Montserrat', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen';
    --pricing-font-family:'Raleway', sans-serif;;
  }

.page {
  width: 75vw;
  background-color:white;
  -webkit-background-color: white;
  -moz-background-color: white;
  -o-background-color: white;
}

.comment-box{
  width: 500px;
  color: var(--font-label-color);
  background: none;
  outline: none;
}

.input-label{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: var(--font-label-color);
    margin-right: 20px;
}

.form-text-large{
  font-family: var(--font-family);
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  color: var(--font-label-color);
}

.form-text-medium-italic{
  font-family: var(--pricing-font-family);
  font-weight: normal;
  font-size: 25px;
  font-style: italic;
  color: var(--font-label-color);

}

.form-button{
  font-family: var(--font-family);
  font-style: normal;
  font-weight: bold;
  font-size: 1em;
  color: var(--font-label-color);
  background-color: white;
  border-radius: 3px;
  box-shadow: none;
  border-color: var(--primary-color);
  border-style: solid;
  padding: 12px 35px 12px 35px;
  outline: none;
}

.form-button:hover{
  cursor: pointer;
  background-color: var(--primary-color);
  color:white;
}

.close-button{
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 25px;
}

.close-button:hover{
  cursor: pointer;
  color:var(--primary-color);
}

.clickable-text-large{
  font-size: 27px;
  font-weight: 700;
  color:var(--primary-color);
}
.clickable-text-large:hover{
  color:var(--primary-color);
  cursor: pointer;
  text-decoration: underline;
}

.clickable-text-medium{
  font-size: 23px;
  font-weight: 700;
  color:var(--primary-color);
}
.text-medium-disabled{
  font-size: 23px;
  font-weight: 700;
  color:var(--disabled-text-color);
}

.clickable-text-medium:hover{
  color:var(--primary-color);
  cursor: pointer;
  text-decoration: underline;
}

.audio-select .MuiOutlinedInput-notchedOutline{
  border-width: 3px;
}

.audio-select{
  display: flex;
  flex-direction: row;
}

.text-medium{
  color:var(--primary-color);
  font-size: 23px;
  font-weight: bold;
}

#iframe-copy-button{
  all: unset;
  -webkit-appearance: none;
  border: 2px solid var(--primary-color);
  color: var(--secondary-label-color);
  box-sizing: border-box;
  border-radius: 5px;
  padding: 8px;
  font-weight: bolder;
  font-size: 1.2em;
  margin-left: 20px;
}

.full-width {
  width: 100% !important;
}

.empty-space {
  margin-top: 100px;
  color: transparent;
}
.dzu-submitButton{
  display: none;
}

.horizontal-center {
  margin-left: auto !important;
  margin-right: auto !important;
}

.row-container {
  all: unset;
  -webkit-appearance: none;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-left: 10px;
  margin-right: 10px;
}

.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.align-center {
  align-items: center;
}
@media (max-width: 1440px) {
  .row-container {
      font-size: 0.9em;
      font-weight: bolder;
  } 
}